// Enable and Disable the return date inputs
function check_checkbox(target) {
  var w_r = $("#with_return");
  if (target.is(":checked")) {
    w_r.show();
    w_r.find("input").prop("disabled", false);
  } else {
    w_r.hide();
    w_r.find("input").prop("disabled", true);
  }
}
// add asterisk on label for every required field
$("[required]").each(function() {
  $("label[for=" + $(this).attr("id") + "]").append(" <span>*</span>");
});
// set minimum return date the same date as departure_date
var departure_date = $("#departure_date");
$("#return_date").attr("min", departure_date.val());
departure_date.on("change", function() {
  $("#return_date").attr("min", departure_date.val());
});
// With return checkbox
var form_return_checkbox = $("#departure_with_return");
check_checkbox(form_return_checkbox);
form_return_checkbox.on("change", function() {
  check_checkbox($(this));
});
// in order to not break the columns
// with a 2 line or 3 lines in some cases
// we make sure all labels have the same height
function same_label_height() {
  var mh = 0;
  $("form label").each(function() {
    var h = $(this).innerHeight();
    mh = (mh < h) ? h : mh;
  });
  $("form label").each(function() {
    $(this).css("height", mh);
  });
}
$("form").submit(function(e) {
  e.preventDefault();
  var formData = new FormData(this);
  var data = {};
  for (var [key, value] of formData.entries()) {
    data[key] = value;
  }
  var btn = $("form .btn");
  btn.prop("disabled", true);
  $.ajax({
    url: "/bk",
    type: "POST",
    data: JSON.stringify(data),
    contentType: "application/json; charset=utf-8",
    success: function() {
      var msg = $("#msg");
      msg.html("");
      msg.addClass("alert alert-success alert-dismissible");
      msg.text("Message Has been sent");
    },
    error: function(e) {
      var msg = $("#msg");
      msg.html("");
      msg.addClass("alert alert-warning alert-dismissible");
      msg.text(e.responseText);
      console.log(e);
      btn.prop("disabled", false);
    }
  });
});
// fixes scroll if url page loads with hash
var hash = window.location.hash;
var init = 1;
if (hash) {
  if (window.location.pathname.indexOf("fleet") !== -1) {
    setTimeout(function() {
      $("[data-filter='" + hash.replace("#", ".") + "']").click();
    }, 500);
  } else {
    window.addEventListener("scroll", function() {
      // select fleet tab from hash url
      if (init) {
        var target = document.querySelector(hash).offsetTop;
        var nav_height = document.querySelector(".navbar-fixed-top").clientHeight;
        setTimeout(function() {
          window.scrollTo(0, target - (nav_height + 15));
        }, 500);
      }
      init = 0;
    });
  }
}
var services = $(".service-item");
var services_inRow = [6, 2];
var row_math = 0;
function services_lineup() {
  var inRow = (window.innerWidth > 767) ? services_inRow[0] : services_inRow[1];
  var heights = services.map(function() {
    $(this).find(".ctx").removeAttr("style");
    return $(this).find(".ctx").height();
  }).get();
  for (var i = 0; i < services.length; i++) {
    if (i % inRow == 0) {
      row_math = (i / inRow);
    }
    $(services[i]).find(".ctx").css("height", Math.max.apply(null, heights.slice(row_math / 1, (row_math + 1) * inRow)) + 15 + 18);
  }
}
services_lineup();
same_label_height();
window.addEventListener("resize", function() {
  services_lineup();
  same_label_height();
});
